import {Box, Button, Menu, MenuItem, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

const NavItem = (props) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const page = props.page;

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    return (
        <Box>
            <Button
                key={page.name}
                onClick={handleOpenNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
                style={{ textTransform: "none" }}
            >
                {page.name}
            </Button>
            {page.sub.length > 0 && <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
            >
                {page.sub.map((subPage) => (
                    <Link to={page.path} style={{textDecoration: 'none', color: "#000"}}>
                        <MenuItem onClick={handleCloseNavMenu}>
                            <Typography textAlign="center">{typeof subPage === 'string' ? subPage : subPage.name}</Typography>
                        </MenuItem>
                    </Link>
                ))}
            </Menu>}
        </Box>
    )
}

export default NavItem;