import './App.css';
import Header from "./components/layouts/Header";
import Home from "./components/pages/Home";
import {Box} from "@mui/material";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import pages from "./configs/pages";
import ComingSoon from "./components/pages/ComingSoon";

function App() {
    return (
        <BrowserRouter>
            <Box>
                <Header/>
                <Routes>
                    <Route path="/" element={<Home mt={2} mb={2}/>}/>
                    {pages.map((page, index) => {
                        return (
                            <Route path={page.path} element={page.el}>
                                {page.sub.map(subPage => {
                                    if (typeof subPage === 'string' || subPage instanceof String)
                                        return <Route page={page.path} element={<ComingSoon/>} />
                                    return <Route path={subPage.path} element={subPage.el} />
                                })}
                            </Route>
                        )
                    })}
                    <Route
                        path="*"
                        element={
                            <main style={{padding: "1rem"}}>
                                <p>There's nothing here!</p>
                            </main>
                        }
                    />
                </Routes>
            </Box>
        </BrowserRouter>
    );
}

export default App;
