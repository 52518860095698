import {Box, Grid} from "@mui/material";
import {Outlet} from "react-router-dom";

const StringTools = () => {
    return (
        <Grid container>
            <Grid item xs={2}>left sider</Grid>
            <Grid item xs={8}>
                <Box>
                    String Tool
                </Box>
                <Outlet />
            </Grid>
            <Grid item xs={2}>right sider</Grid>
        </Grid>
    );
};

export default StringTools;