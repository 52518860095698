import {Box, Button, Container, Grid, Paper} from "@mui/material";
import pages from "../../configs/pages";
import {Link} from "react-router-dom";

const Home = (props) => {
    return (
        <Container sx={{mt: props.mt, mb: props.mb}}>
            {pages.map(page => {
                return (
                    <Box sx={{flexGrow: 1}}>
                        <Box sx={{py: 2}}>{page.name}</Box>
                        <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
                            {page.sub.map((subPage, index) => {
                                if (typeof subPage === 'string')
                                    return (
                                        <Grid item xs={2} sm={4} md={4} key={index}>
                                            <Link to={page.path} style={{ textDecoration: 'none' }}>
                                                <Button sx={{textTransform: "none"}} variant="outlined">{subPage}</Button>
                                            </Link>
                                        </Grid>
                                    );
                                return (
                                    <Grid item xs={2} sm={4} md={4} key={index}>
                                        <Link to={page.path + '/' + subPage.path} style={{ textDecoration: 'none' }}>
                                            <Button sx={{textTransform: "none"}} variant="outlined">{subPage.name}</Button>
                                        </Link>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                )
            })}
        </Container>
    )
}

export default Home;