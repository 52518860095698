import React from 'react';
import {Box, Button, Grid, Stack, TextField} from "@mui/material";

const UrlEncode = () => {
    const [result, setResult] = React.useState("");

    const handleChange = (event) => {
        setResult(encodeURIComponent(event.target.value));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Box>
                    <Box>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Input"
                            fullWidth
                            multiline
                            minRows={20}
                            maxRows={20}
                            onChange={handleChange}/>
                    </Box>

                    <Box
                        sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <Stack spacing={2}>
                                    <Button variant="outlined">Run</Button>
                                    <Button variant="outlined">Reset</Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={11}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Result"
                                    fullWidth
                                    multiline
                                    disabled
                                    value={result}
                                    minRows={4}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={4}>
                option
            </Grid>
        </Grid>
    );
};

export default UrlEncode;